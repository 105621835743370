import React, { useContext } from 'react'
import {
	BrowserRouter,
	Routes,
	Route,
	Navigate,
	Outlet,
} from 'react-router-dom'
import { AuthProvider, AuthContext } from './context'
import Home from '../Containers/Home'
import Login from '../Containers/Login'

const PrivateRoute = ({ component: RouteComponent, ...rest }: any) => {
	const { currentUser } = useContext(AuthContext)
	return !!currentUser ? <Outlet /> : <Navigate to={'/login'} />
}

export default function Router() {
	return (
		<BrowserRouter>
			<AuthProvider>
				<Routes>
					<Route path='/' element={<PrivateRoute />}>
						<Route path='/' element={<Home />} />
					</Route>
					<Route path='/login' element={<Login />} />
				</Routes>
			</AuthProvider>
		</BrowserRouter>
	)
}
