import React, { Component, useState } from 'react'
import { app, auth } from '../Utils/firebase'
import {
	signInWithPhoneNumber,
	RecaptchaVerifier,
	Auth,
	UserCredential,
	signInWithEmailAndPassword,
} from 'firebase/auth'
import { useNavigate } from 'react-router-dom'
import Avatar from '@mui/material/Avatar'
import Button from '@mui/material/Button'
import CssBaseline from '@mui/material/CssBaseline'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import Link from '@mui/material/Link'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import Typography from '@mui/material/Typography'
import Container from '@mui/material/Container'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import logo from '../Components/logo.jpg'
import { FirebaseError } from 'firebase/app'

declare var window: any

const theme = createTheme()

const Login = () => {
	const countryCode = '+47'
	const navigate = useNavigate()

	const [phoneNumber, setPhoneNumber] = useState(countryCode)
	const [username, setUsername] = useState('')
	const [password, setPassword] = useState('')
	const [exspandForm, setExspandForm] = useState(false)
	const [OTP, setOTP] = useState('')
	const [error, setError] = useState<FirebaseError>()
	const [loginType, setLoginType] = useState('email')

	const generateRecaptcha = () => {
		window.recaptchaVerifier = new RecaptchaVerifier(
			'recaptcha-container',
			{
				size: 'invisible',
			},
			auth
		)
	}

	const requestOTP = (e: any) => {
		e.preventDefault()
		if (phoneNumber.length >= 11) {
			setExspandForm(true)
			generateRecaptcha()

			let appVerifier = window.recaptchaVerifier
			signInWithPhoneNumber(auth, phoneNumber, appVerifier)
				.then(confirmationResult => {
					window.confirmationResult = confirmationResult
				})
				.catch(error => {
					console.error('error', error)
				})
		}
	}

	const verifyOTP = (e: any) => {
		e.preventDefault()

		if (OTP.length === 6) {
			let confirmationResult = window.confirmationResult
			confirmationResult
				.confirm(OTP)
				.then((result: UserCredential) => {
					const user = result.user
					console.log('this is the user', user)
					navigate('/')
				})
				.catch((error: any) => {
					console.error(error)
				})
		}
	}

	const loginUsername = (e: any) => {
		e.preventDefault()
		setError(undefined)

		signInWithEmailAndPassword(auth, username, password)
			.then((result: UserCredential) => {
				const user = result.user
				console.log('user is', user)
				navigate('/')
			})
			.catch((error: FirebaseError) => {
				setError(error)
				console.error('Error logging in', error)
				console.log(error)
			})
	}

	const changeLoginType = (type: 'email' | 'phone') => {
		setError(undefined)
		setLoginType(type)
	}

	return (
		<div className='LoginContainer'>
			<ThemeProvider theme={theme}>
				<Container component='main' maxWidth='xs'>
					<video
						id='background-video'
						style={{
							position: 'absolute',
							top: 0,
							left: 0,
							minHeight: '100%',
							minWidth: '100%',
							zIndex: -99,
						}}
						muted
						autoPlay
						loop
					>
						<source
							src={
								'https://player.vimeo.com/external/578367093.sd.mp4?s=094f7ca8d59a09c285def0747343c774eedacfea&profile_id=165&oauth2_token_id=57447761'
							}
							type='video/mp4'
						/>
					</video>
					<CssBaseline />
					<Box
						sx={{
							marginTop: 8,
							paddingBottom: 8,
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							background: 'white',
							color: 'black',
						}}
					>
						{error?.code === 'auth/user-not-found' && (
							<Box
								sx={{
									background: 'darkRed',
									color: 'white',
									display: 'flex',
									flexDirection: 'column',
									width: '100%',
									textAlign: 'center',
								}}
							>
								<p>User not fount</p>
							</Box>
						)}
						<img src={logo} style={{ maxWidth: '80%', marginTop: 14 }} />
						<Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
							<LockOutlinedIcon />
						</Avatar>
						<Typography component='h1' variant='h5'>
							Sign in
						</Typography>
						<Box>
							<Button
								onClick={() => {
									changeLoginType('email')
								}}
							>
								Email
							</Button>
							<Button
								onClick={() => {
									changeLoginType('phone')
								}}
							>
								Phone
							</Button>
						</Box>
						{loginType === 'email' && (
							<Box
								component='form'
								onSubmit={loginUsername}
								noValidate
								sx={{ mt: 1, width: '80%' }}
							>
								<TextField
									margin='normal'
									required
									fullWidth
									id='username'
									label='Username'
									name='Username'
									autoComplete='username'
									autoFocus
									onChange={e => {
										setUsername(e.target.value)
									}}
								/>

								<TextField
									margin='normal'
									required
									fullWidth
									name='Password'
									label='Password'
									id='password'
									autoComplete='password'
									type='password'
									onChange={(e: any) => {
										setPassword(e.target.value)
									}}
								/>
								<Button
									disabled={password.length < 6}
									type='submit'
									fullWidth
									variant='contained'
									sx={{ mt: 3, mb: 2 }}
								>
									Login
								</Button>
							</Box>
						)}
					</Box>
				</Container>
			</ThemeProvider>
		</div>
	)
}

export default Login

/*
{loginType === 'phone' && (
							<Box component='form' onSubmit={requestOTP} noValidate sx={{ mt: 1 }}>
								<TextField
									margin='normal'
									required
									fullWidth
									id='phone'
									label='Phone'
									name='+47 123 45 678'
									autoComplete='phone'
									autoFocus
									onChange={e => {
										setPhoneNumber(e.target.value)
									}}
								/>
								{exspandForm === true ? (
									<>
										<TextField
											margin='normal'
											required
											fullWidth
											name='otp'
											label='SMS Code'
											id='otp'
											autoComplete='otp'
											onChange={(e: any) => {
												setOTP(e.target.value)
											}}
										/>
										<Button
											disabled={OTP.length < 6}
											onClick={verifyOTP}
											fullWidth
											variant='contained'
											sx={{ mt: 3, mb: 2 }}
										>
											Login
										</Button>
									</>
								) : (
									<Button
										type='submit'
										fullWidth
										variant='contained'
										sx={{ mt: 3, mb: 2 }}
									>
										Get code
									</Button>
								)}
								<div id='recaptcha-container'></div>
							</Box>
						)}
*/
