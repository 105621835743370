import { Button } from '@mui/material'
import React, { useContext } from 'react'
import { signOut } from 'firebase/auth'
import { app, auth } from '../Utils/firebase'
import { AuthContext } from '../Utils/context'

export default function Home() {
	const { currentUser } = useContext(AuthContext)
	return (
		<div>
			<header className='App-header'>
				<h3>Welcome {currentUser?.email}</h3>
				<img src='https://scontent.fosl4-1.fna.fbcdn.net/v/t1.18169-9/15442184_10154047476157513_8184803900892017380_n.jpg?_nc_cat=111&ccb=1-5&_nc_sid=174925&_nc_ohc=JpkjRHvyNQIAX9XJfFW&tn=htcuPw9bYbAHIDgi&_nc_ht=scontent.fosl4-1.fna&oh=00_AT_JpKGrj_FrhGnrgq82A2l5Xb93qVRsa9Se69DUO2mJSw&oe=621263AB' />
				<Button
					variant='contained'
					style={{
						marginTop: '1em',
					}}
					onClick={() => {
						signOut(auth).catch(error => console.error(error))
						console.log('Bye Felicia!')
					}}
				>
					Sign Out
				</Button>
			</header>
		</div>
	)
}
