import React, { useContext } from 'react'
import './index.css'
import Button from '@mui/material/Button'
import AccessibilityIcon from '@mui/icons-material/Accessibility'
import Router from './Utils/Router'
import Loading from './Containers/Loading'

function App() {
	return <Router />
}

export default App
