import { MultiFactorUser, User } from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { app, auth } from './firebase'

interface context {
	currentUser: User | undefined
}

export const AuthContext = React.createContext<context>({
	currentUser: undefined,
})

export const AuthProvider = ({ children }: any) => {
	const [currentUser, setCurrentUser] = useState<User>()
	const [pending, setPending] = useState(true)

	useEffect(() => {
		auth.onAuthStateChanged((user: any) => {
			setCurrentUser(user)
			setPending(false)
		})
	}, [])

	if (pending) {
		return <>Loading...</>
	}

	return (
		<AuthContext.Provider
			value={{
				currentUser,
			}}
		>
			{children}
		</AuthContext.Provider>
	)
}
